<template>
  <article :class="`Card-restaurant--${variant}`" class="Card-restaurant" ref="cardRef">
    <div class="Card-restaurant__content">
      <ux-atoms-typo :as="titleTag" class="Card-restaurant__title" color="dark" v-if="title && href" variant="text-heading-01">
        <ux-atoms-link :classic="false" :to="restaurantLink" @click="$emit('cardRestaurant::interact', title)" class="Card-restaurant__title-link"
          >{{ title }}
        </ux-atoms-link>
      </ux-atoms-typo>
      <ux-atoms-address :address="address" v-if="address" />
      <div class="Card-restaurant__cooking" v-if="cookingTypes || stars">
        <ux-atoms-typo :text="cookingTypes" as="p" color="light" v-if="cookingTypes" variant="text-small" />
        <span aria-hidden="true" class="Card-restaurant__separator" v-if="cookingTypes && stars">•</span>
        <ux-atoms-michelin-stars :stars="stars" v-if="stars" />
      </div>
      <div class="Card-restaurant__rate-price" v-if="(price && currency) || reviewCount || averageRating">
        <ux-atoms-rating :comments="reviewCount" :stars="averageRating" v-if="averageRating" />
        <span aria-hidden="true" class="Card-restaurant__separator" v-if="reviewCount && averageRating && price">•</span>
        <ux-atoms-average-price :currency="currency" :price="price" has-modal v-if="price && currency" variant="CardRestaurant" />
      </div>
    </div>
    <div
      :class="[{ 'Card-restaurant__availabilities--favorites': variant === VariantType.Favorites }]"
      class="Card-restaurant__availabilities"
      v-if="hasPartner || phone || availabilities || partnerWidgetMatch(partnerId, bookingWidgetUrl)"
    >
      <ux-atoms-chip-text as="div" v-if="!hasPartner && phone">
        <span>{{ $t('ux.molecules.restaurantAvailabilities.phoneNumberLabel') }}</span>
        <a :href="`tel:${phone}`" class="Card-restaurant__content-link">{{ phone }}</a>
      </ux-atoms-chip-text>
      <ux-molecules-card-restaurant-availabilities
        :availabilities="availabilities"
        :booking-widget-url="bookingWidgetUrl"
        :has-partner="hasPartner"
        :hide-availabilities="hideAvailabilities"
        :id="id"
        :partner-id="partnerId"
        :variant="variant"
        v-else
      />
    </div>

    <div class="Card-restaurant__tags">
      <ux-atoms-tag v-if="hasOffers" variant="offer" />
      <ux-atoms-tag
        :label="$t(`ux.molecules.cardRestaurant.enrolmentPrograms.${enrolmentPrograms?.diningOfferParticipation.code}`)"
        v-if="displayEnrolmentPrograms"
        variant="enrolment"
      />
    </div>
    <div :class="[{ 'Card-restaurant__media--favorites': variant === VariantType.Favorites }]" class="Card-restaurant__media">
      <Carousel :id="uniqueId" :items-to-scroll="1" :items-to-show="1" aria-live="off" ref="carousel" v-if="displayCarousel" wrap-around>
        <Slide :key="JSON.stringify(image)" class="Card-restaurant__slide" v-for="image in medias">
          <ux-atoms-image :alt="alt" :src="displayCorrectFormat(image)" v-if="alt" />
        </Slide>
      </Carousel>
      <ux-atoms-image :alt="alt" :media="media" :src="src" v-else />
    </div>
    <div class="Card-restaurant__nav" v-if="displayCarousel">
      <ux-atoms-button :aria-controls="uniqueId" @click="prev" class="Card-restaurant__nav-button" label="prev" size="s" variant="control">
        <ux-atoms-icon name="chevron-left" size="m" />
      </ux-atoms-button>
      <ux-atoms-button :aria-controls="uniqueId" @click="next" class="Card-restaurant__nav-button" label="next" size="s" variant="control">
        <ux-atoms-icon name="chevron-right" size="m" />
      </ux-atoms-button>
    </div>
  </article>
</template>
<script setup lang="ts">
import { Carousel, Slide } from 'vue3-carousel';

import type { RestaurantIdentifier } from '~/graphql';

import { type mediaType } from '~/components/ux/atoms/Image/types';
import { partnerWidgetMatch } from '~/helpers/partnerWidget';
import { defaultDateQueryParams, defaultGuestQueryParams } from '~/helpers/urlQueryParams.js';

import { EnrolmentPrograms, VariantType } from './types.js';

export interface CardRestaurantProps {
  address?: string;
  alt?: string;
  availabilities?: [];
  averageRating?: number;
  bookingWidgetUrl?: string;
  currency?: string;
  enrolmentPrograms?: EnrolmentPrograms;
  facetCodes?: string[];
  foodType?: string;
  hideAvailabilities?: boolean;
  href?: string;
  id?: string;
  identifiers?: RestaurantIdentifier[];
  media?: mediaType;
  medias?: mediaType[];
  partnerId?: string;
  phone?: string;
  preventRedirect?: boolean;
  price?: number;
  reviewCount?: number;
  src?: string;
  stars?: 0 | 1 | 2 | 3;
  title?: string;
  variant?: VariantType;
}

const props = withDefaults(defineProps<CardRestaurantProps>(), {
  address: '',
  alt: undefined,
  availabilities: undefined,
  averageRating: undefined,
  bookingWidgetUrl: undefined,
  currency: '',
  enrolmentPrograms: undefined,
  facetCodes: undefined,
  foodType: undefined,
  hideAvailabilities: false,
  href: '',
  id: undefined,
  identifiers: undefined,
  media: undefined,
  medias: undefined,
  partnerId: undefined,
  phone: undefined,
  preventRedirect: false,
  price: 0,
  reviewCount: undefined,
  src: undefined,
  stars: 0,
  title: '',
  variant: VariantType.Default
});

defineEmits(['cardRestaurant::interact']);

const carousel = ref();
const cardRef = ref();
const uniqueId = useId();
const { isDesktop, isMobile } = useCurrentWindowSize();
const localePath = useLocalePath();
const route = useRoute();
const { searchParams } = useSearchRestaurant();
const { t } = useI18n();

const titleTag = computed(() => ([VariantType.List, VariantType.Map].includes(props.variant) ? 'h2' : 'p'));

const displayEnrolmentPrograms = computed(
  () => props.enrolmentPrograms && ['LEARN', 'LEARNP'].includes(props.enrolmentPrograms.diningOfferParticipation.code)
);

const displayCorrectFormat = (image: mediaType) => {
  return image.formats.find((format) => format.format === '346x260')?.path || image.formats.find((format) => format.format === '1024x768')?.path;
};

const cookingTypes = computed(() => {
  const mainThematic: string | undefined = props.facetCodes && props.facetCodes.length > 0 && !isMobile.value ? props.facetCodes[0] : undefined;
  const result: string =
    (props.foodType ?? '') +
    (props.foodType && mainThematic ? ', ' : '') +
    (mainThematic ? t(`ux.molecules.cardRestaurant.mainThematics.${mainThematic}`) : '');

  return result !== '' ? result : undefined;
});

const displayCarousel = computed(
  () => props.medias && props.medias.length > 1 && (props.variant === VariantType.List || (props.variant === VariantType.Map && isDesktop.value))
);

const hasOffers = computed(() => {
  return props.availabilities?.find((availability: any) => availability?.offers?.length);
});

const poi = computed(() => {
  if ((route?.name as string).startsWith('collection') || (route?.name as string).startsWith('offer')) {
    return undefined;
  }

  return route.params.slug ?? undefined;
});

const restaurantLink = computed(() => {
  if (!props.preventRedirect) {
    return localePath({
      name: 'restaurant-restaurantCode',
      params: { restaurantCode: props.id },
      query: {
        date: defaultDateQueryParams(searchParams.value.date),
        guests: defaultGuestQueryParams(searchParams.value.groupSize),
        poi: poi.value ?? undefined
      }
    });
  }
});

const prev = () => {
  carousel.value?.prev();
};

const next = () => {
  carousel.value?.next();
};

const hasPartner = computed(() => !!(props.identifiers?.some((identifier) => identifier.system) || (props.partnerId && props.bookingWidgetUrl)));
</script>
<style lang="scss" scoped>
@use 'CardRestaurant.scss';
</style>
