<template>
  <ul class="Card-restaurant-availabilities">
    <li
      v-if="
        partnerWidgetMatch(partnerId, bookingWidgetUrl) ||
        (hasPartner && (!filteredAvailabilities || filteredAvailabilities?.length === 0) && hideAvailabilities)
      "
    >
      <ux-atoms-link :href="bookingLink()" :target="'_self'" underline>
        <ux-atoms-chip-text as="span" class="Card-restaurant-availabilities__chip">
          {{ $t('ux.molecules.restaurantAvailabilities.seeAvailabilities') }}
        </ux-atoms-chip-text>
      </ux-atoms-link>
    </li>
    <template v-else-if="filteredAvailabilities?.length">
      <li
        :class="[{ 'Card-restaurant-availabilities__availability--favorites': variant === VariantType.Favorites }]"
        :key="JSON.stringify(availability)"
        class="Card-restaurant-availabilities__availability"
        v-for="availability in filteredAvailabilities"
      >
        <ux-atoms-link
          :aria-label="$t('ux.molecules.restaurantAvailabilities.bookATableAt', { time: availability.slot })"
          :classic="false"
          :href="bookingLink(availability.slot)"
          :target="'_self'"
        >
          <ux-atoms-chip-text as="span">
            {{ availability.slot }}
            <span class="Card-restaurant-availabilities__icon-container" v-if="availability?.offers?.length">
              <span class="accessibility-sr-only">{{ $t('ux.molecules.restaurantAvailabilities.hasOffer') }}</span>
              <ux-atoms-icon name="offer" />
            </span>
          </ux-atoms-chip-text>
        </ux-atoms-link>
      </li>
      <li class="Card-restaurant-availabilities__availability" v-if="availabilities && availabilities.length > 2">
        <ux-atoms-link :aria-label="$t('ux.molecules.restaurantAvailabilities.viewMore')" :classic="false" :href="bookingLink()">
          <ux-atoms-chip-text as="span">
            <span class="Card-restaurant-availabilities__icon-container Card-restaurant-availabilities__icon-container--more">
              <ux-atoms-icon name="menu-dots" />
            </span>
          </ux-atoms-chip-text>
        </ux-atoms-link>
      </li>
    </template>
    <li class="Card-restaurant-availabilities__unavailable" v-else-if="hasPartner">
      <ux-atoms-tag :label="$t('ux.atoms.tag.noAvailability')" class="Card-restaurant-availabilities__tag" variant="no-availability" />
      <span aria-hidden="true">•</span>
      <ux-atoms-link :href="nextAvailabilities" class="Card-restaurant-availabilities__button" underline>
        {{ $t('pages.restaurant.nextAvailabilities') }}
      </ux-atoms-link>
    </li>
  </ul>
</template>
<script lang="ts" setup>
import { VariantType } from '~/components/ux/molecules/CardRestaurant/types.js';
import { EDITORIAL_CONTENT_TYPE } from '~/core/constants';
import { AvailabilitySlot } from '~/graphql';
import { partnerWidgetMatch } from '~/helpers/partnerWidget';

export interface CardRestaurantAvailabilitiesProps {
  availabilities?: AvailabilitySlot[];
  bookingWidgetUrl?: string;
  hasPartner?: boolean;
  hideAvailabilities?: boolean;
  id?: string;
  partnerId?: string;
  restaurantUrl?: string;
  variant?: VariantType;
}

const props = withDefaults(defineProps<CardRestaurantAvailabilitiesProps>(), {
  availabilities: undefined,
  bookingWidgetUrl: undefined,
  hasPartner: undefined,
  hideAvailabilities: false,
  id: undefined,
  partnerId: undefined,
  restaurantUrl: undefined,
  variant: undefined
});

const route = useRoute();
const { searchParams } = useSearchRestaurant();
const localePath = useLocalePath();

const hasOffers = computed(() => {
  return props.availabilities?.find((availability) => availability?.offers?.length);
});

const filteredAvailabilities = computed(() => {
  return props.availabilities?.slice(0, hasOffers.value ? 2 : 3);
});

const poi = route.params?.slug ?? undefined;

const fromEditorialContent = computed(() =>
  [EDITORIAL_CONTENT_TYPE.COLLECTION, EDITORIAL_CONTENT_TYPE.OFFER].some((type) => (route?.name as string).startsWith(type))
);

const bookingLink = (slot?: string) => {
  return localePath({
    name: 'restaurant-restaurantCode',
    params: { restaurantCode: props.id },
    query: {
      book: 'true',
      date: searchParams.value.date,
      guests: searchParams.value.groupSize,
      poi: fromEditorialContent.value ? undefined : poi,
      slot: slot ?? undefined
    }
  });
};

const nextAvailabilities = computed(() => {
  return localePath({
    name: 'restaurant-restaurantCode',
    params: { restaurantCode: props.id },
    query: {
      date: searchParams.value.date,
      guests: searchParams.value.groupSize,
      nextAvailability: 'true',
      poi: fromEditorialContent.value ? undefined : poi
    }
  });
});
</script>
<style lang="scss" scoped>
@use 'CardRestaurantAvailabilities.scss';
</style>
