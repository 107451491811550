import type { mediaType } from '~/components/ux/atoms/Image/types';

export enum VariantType {
  Default = 'default',
  Favorites = 'favorites',
  List = 'list',
  Map = 'map'
}

export type EnrolmentPrograms = {
  availablePrograms: string[];
  diningOfferParticipation: {
    code: string;
    description: string;
  };
};

export type cardType = {
  address: string;
  alt: string;
  averageRating: number;
  currency: string;
  enrolmentPrograms: EnrolmentPrograms;
  foodType: string;
  hasPartner?: string;
  hotelCode?: string;
  href: string;
  id: string;
  lat?: number;
  lon?: number;
  media: mediaType;
  medias: mediaType[];
  phone?: string;
  price: number;
  reviewCount: number;
  src: string;
  stars: 0 | 1 | 2 | 3;
  title: string;
  variant?: VariantType;
};

export type cardListType = cardType[];
